<template>
  <div>
    <el-form :inline="true" :model="searchForm" class="demo-form-inline">
      <el-form-item label="设备编号">
        <el-input v-model="searchForm.haiLiDeviceNo" placeholder="请输入设备编号" clearable/>
      </el-form-item>
      <el-form-item label="日期">
        <el-date-picker
          v-model="timeArr"
          type="datetimerange"
          clearable
          format="yyyy-MM-dd HH:mm:ss"
          value-format="timestamp"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间">
        </el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="onSubmit">查询</el-button>
      </el-form-item>
    </el-form>
    <div class="mb20">
      <p class="mb20">
        <span>行驶里程：</span>
        <span class="ml24">{{mapData.distance || 0}}</span>公里
      </p>
      <p>
        <span>当前定位地址：</span>
        <!-- <span>{{mapData.address || '无'}}</span> -->
        <span>{{formattedAddress || mapData.address || '无'}}</span>
      </p>
    </div>
    <div class="batteryTrack">
      <el-amap vid="batteryTrack" :zoom="zoom" :center="center" :plugin="plugin">
        <el-amap-polyline
          :editable="polyline.editable"
          :path="polyline.path"
          :strokeColor="polyline.strokeColor"
        ></el-amap-polyline>
        <el-amap-marker
          v-for="(marker, index) in markers"
          :position="marker.position"
          :visible="marker.visible"
          :draggable="marker.draggable"
          :vid="index"
          :label="marker.label"
          :events="marker.events"
          :icon="marker.icon"
          :offset="marker.offset"
          v-bind:key="index"
        ></el-amap-marker>
      </el-amap>
    </div>
    <div class="fe df">
      <el-button class="mt20" type="primary" @click="$router.push('/leaseholdMgmt/batteryList')">返回</el-button>

    </div>
  </div>
</template>

<script>

import { timeToDate, getCurrentDate } from '@/utils/date-format'
import { travelDeviceLocation} from '@/api/facility'
import unSelectImg  from '../../assets/ic-circle-unselected.png'
import selectImg from '../../assets/ic-circle-selected.png'
import WGS from '../../js/WGS.js' 
export default {
  data() {
    const self = this;
    return {
      // 地图获取文字位置信息
      plugin: [
        {
          pName: 'Geocoder',
          events: {
            init(o) {
              //定位第一次逆解码
              o.getAddress(self.center, (status, result) => {
                if (status === 'complete' && result.info === 'OK') {
                  self.formattedAddress=result.regeocode.formattedAddress
                }

              })
            }
          }
        }
      ],
      formattedAddress:'',
      
      mapData:'',
      searchForm: {
        haiLiDeviceNo: '',
        endTime: '',
        startTime: '',
      },
      timeArr:[],
      /** 折线 */
      polyline: {
        path: [],
        editable: false,
      },
      /** 点的轨迹 */
      markers: [],
      zoom: 15,
      center: [117.262102,39.127833],
      trackPoint: {},
      selectIndex: null,

    }
  },
  mounted() {
    this.onSubmit();
  },
  methods: {
    // 高德地图获取文字位置
    getaddress: function(lnglat) {
      let self = this;
      AMap.plugin('AMap.Geocoder', function() {
        var geocoder = new AMap.Geocoder({
          // city 指定进行编码查询的城市，支持传入城市名、adcode 和 citycode
          city: '010'
        })
        geocoder.getAddress(lnglat, function(status, result) {
          if (status === 'complete' && result.info === 'OK') {
            self.formattedAddress=result.regeocode.formattedAddress
            // result为对应的地理位置详细信息
          }
        })
      })
    },
    onSubmit() {
      if (this.timeArr && this.timeArr.length !== 0 ) {
        this.searchForm.startTime = this.timeArr[0];
        this.searchForm.endTime = this.timeArr[1];
      } 
      else{
        this.timeArr = [];
        this.searchForm.startTime = new Date().getTime() - 1000*60*60*12;
        this.searchForm.endTime = new Date().getTime();
        this.timeArr.push(this.searchForm.startTime,this.searchForm.endTime)
      }
      if(!this.searchForm.haiLiDeviceNo){
        this.searchForm.haiLiDeviceNo = this.$route.query.haiLiDeviceNo;
      }
      this.travelDeviceLocation()
    },
    async travelDeviceLocation() {
      const loading = this.$loading({
          lock: true,
          text: "数据正在加载，请稍候...",
          spinner: "el-icon-loading",
          background: "rgba(0, 0, 0, 0.7)"
      });
      const params = {...this.searchForm};

      try{
        const res = await travelDeviceLocation(params);
        this.mapData = res.mdata;
        if(+res.mdata.deviceType == 5 && res.mdata.latitude && res.mdata.longitude){
          const WGSObj = WGS.gcj_encrypt(+res.mdata.latitude,+res.mdata.longitude);
          const lat = WGSObj.lat;
          const lon = WGSObj.lon;
          this.$set(this.center,0,lon);
          this.$set(this.center,1,lat);
        }
        if(+res.mdata.deviceType == 3 && res.mdata.latitude && res.mdata.longitude){
          this.$set(this.center,0,res.mdata.longitude);
          this.$set(this.center,1,res.mdata.latitude);
        }
        
        this.getaddress(this.center);//显示地位位置文字
        const ldata = res.mdata.trackPoint;
        this.polyline.path = [];
        this.markers = [];
        if(!ldata.length){
          loading.close();
          this.polyline.strokeColor = 'transparent';
          return;
        }else{
          this.polyline.strokeColor = "#FA0000FF";
        }
        ldata.forEach((element, index, arr) => {
          let positionObj;
          if(+res.mdata.deviceType === 5){//5转3不转
            positionObj = WGS.gcj_encrypt(element.coordinate[0],element.coordinate[1])
            this.polyline.path.push([positionObj.lon, positionObj.lat]);
          }
          if(+res.mdata.deviceType === 3){
            this.polyline.path.push([element.coordinate[1], element.coordinate[0]]);
          }
          this.markers.push({
            position: [positionObj.lon || element.coordinate[1], positionObj.lat || element.coordinate[0]],
            visible: true,
            draggable: false,
            label: {
              content: element.miles,
              offset: [-20, -20]
            },
            events: {
              },
            icon: unSelectImg,
            offset: [-5, -5]
          })
        })
      }catch(e){
        console.log(e,'e');
      }
      loading.close();
    },
  },
  components: {

  }
}
</script>

<style scoped lang='less'>
.batteryTrack {
  width: 100%;
  height: 600px;
}
</style>
